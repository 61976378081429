import React from 'react';
import {useErrorMessagesState, useErrorMessagesDispatch} from './error-messages-context';
import XLogo from '../../assets/icons/x.svg';
import AlertCircle from '../../assets/icons0/alert-circle.svg';
import './error-messages.scss';
import {FormattedMessage} from 'react-intl';
import Button, {LinkButton} from '../../components/button/button';
import ArrowNarrowRight from '../../assets/icons0/arrow-narrow-right.svg';
import {TELEGRAM_SUPPORT} from '../../constants';
import InfoCircle from '../../assets/icons0/info-circle.svg';
import ArrowNarrowDown from '../../assets/icons0/arrow-narrow-down.svg';

export const ERRORS = {
    'windowRecaptcha': '1',
    'serverRecaptcha': '2',
    'serviceInvalid': '3',
    'wayforpay': '4',
    'fondy': '5',
    'endpointInvalid': '6',
    'technicalMerchantPools': '7',
    'serviceHasPools': '8',
    'p2pblock': '9',
    'fondyBlock': '10'
};

const ErrorMessage = ({errorCode, closeError, additionalMessage, title, description}) => {
    return (
        <div className='error-message-container padding-1rem background-color-error-25 border-1px-solid border-color-error-300 border-radius-0dot75rem position-relative' data-gtm={`error error-type-${ERRORS[errorCode] || 0}`}>
            <div className='position-absolute top-1rem left-1rem'>
                <AlertCircle className='display-block width-1dot5rem height-1dot5rem checkout-shell-alert-icon'/>
            </div>
            <div className='position-absolute top-1dot25rem right-1rem' onClick={closeError} data-gtm='error-btn close'>
                <XLogo className='display-block width-1rem height-1rem color-error-500 cursor-pointer'/>
            </div>
            <div className='color-error-700 padding-left-2dot5rem text-sm'>
                <p className='semibold padding-top-0dot125rem'>
                    {!title && <FormattedMessage defaultMessage='We are having connection problems'/>}
                    {!!title && title}
                </p>
                <p className='color-error-600 padding-top-0dot625rem'>
                    {!description && <FormattedMessage defaultMessage='Please check your internet connection and try again.'/>}
                    {!!description && description}
                </p>
                <div className='padding-top-0dot75rem display-flex'>
                    <p className='color-error-700 text-decoration-underline padding-right-0dot5rem'>
                        <FormattedMessage defaultMessage='Error'/>
                        {' №'}{ERRORS[errorCode] || 0}
                    </p>
                    <LinkButton {...{color: 'error', plain: true, size: 'sm', iconPosition: 'right', link: {href: TELEGRAM_SUPPORT, target: '_blank', gtm: 'error-btn support'}}}>
                        <FormattedMessage defaultMessage='Support service'/>
                        <ArrowNarrowRight className='display-block height-1dot25rem width-1dot25rem'/>
                    </LinkButton>
                </div>
                {additionalMessage && <p className='padding-top-0dot75rem additional-message color-error-400 word-break-break-all'>{additionalMessage}</p>}
            </div>
        </div>
    )
};

const NotificationMessage = ({closeError, additionalMessage, title, description, showSupport, action}) => {
    return (
        <div className='error-message-container background-color-white padding-1rem border-radius-0dot75rem position-relative' data-gtm={`error error-type-notification`}>
            <div className='position-absolute top-1rem left-1rem'>
                <InfoCircle className='display-block width-1dot5rem height-1dot5rem checkout-shell-info-icon'/>
            </div>
            <div className='position-absolute top-1dot25rem right-1rem' onClick={closeError} data-gtm='error-btn close'>
                <XLogo className='display-block width-1rem height-1rem color-gray-500 cursor-pointer'/>
            </div>
            <div className='color-gray-700 padding-left-2dot5rem text-sm'>
                <p className='semibold padding-top-0dot125rem'>
                    {title}
                </p>
                <p className='color-gray-500 padding-top-0dot625rem'>
                    {description}
                </p>
                <div className='padding-top-0dot75rem display-flex column-gap-0dot5rem'>
                    {!action &&
                        <Button {...{color: 'primary', plain: true, size: 'sm', iconPosition: 'right', gtm: 'error-btn got-it'}}>
                            <FormattedMessage defaultMessage='Understood'/>
                            <ArrowNarrowDown className='display-block height-1dot25rem width-1dot25rem'/>
                        </Button> 
                    }
                    {!!action && action}
                    {showSupport && 
                        <LinkButton {...{color: 'pale', plain: true, size: 'sm', iconPosition: 'right', link: {href: TELEGRAM_SUPPORT, target: '_blank', gtm: 'error-btn support'}}}>
                            <FormattedMessage defaultMessage='Support service'/>
                            <ArrowNarrowRight className='display-block height-1dot25rem width-1dot25rem'/>
                        </LinkButton>
                    }
                </div>
            </div>
        </div>
    )
};

const components = {
    'notification': NotificationMessage,
    'default': ErrorMessage
};

const Message = React.memo(({item}) => {
    const MessageComponent = components[item.type] || ErrorMessage;
    const dispatch = useErrorMessagesDispatch();
    return (
        <MessageComponent {...{...item}} closeError={() => dispatch({type: 'remove', payload: item.key})}/>
    )
});


const ErrorMessages = React.memo(() => {
    const state = useErrorMessagesState();
    return (
        <div className='position-fixed top-1rem right-1rem z-index-1' data-gtm='errors-box'>
            {state.map((item) => (
                <Message key={item.key} {...{item}}/>
            ))}
        </div>
    )
});

export default React.memo(ErrorMessages);