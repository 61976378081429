import React from 'react';
import './Shell.scss';
import QrCode02Svg from '../../../assets/icons0/qr-code-02.svg';
import Ticket01Svg from '../../../assets/icons0/ticket-01.svg';
import ShoppingCart01Svg from '../../../assets/icons0/shopping-cart-01.svg';
import CreditCardRefreshSvg from '../../../assets/icons0/credit-card-refresh.svg';
import classNames from 'classnames';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const RAZZLE_APP_MASTERWASH_SERVICE = process.env.RAZZLE_APP_MASTERWASH_SERVICE;

const MenuLarge = React.memo(({menuLargeRef, menuFlag, toggleMenu}) => {
    const location = useLocation();
    return (
        <div
            ref={menuLargeRef}
            className={classNames('display-none', {
                'display-block': menuFlag
            })}
        >
            <div className='position-absolute background-color-white border-1px-solid-black border-top-color-gray-200 border-bottom-color-gray-200 border-left-color-gray-200 border-right-color-gray-200 border-radius-0dot5rem z-index-1' style={{top: 38, left: -32}}>
                <div className='padding-2rem'>
                    <div>
                        <Link to={`/events${searchFilter(location.search)}`} onClick={toggleMenu}>
                            <div className='padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><Ticket01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="Electronic tickets" /></div>
                            </div>
                        </Link>
                        <Link to={`/master-wash/services/${RAZZLE_APP_MASTERWASH_SERVICE}${searchFilter(location.search)}`} onClick={toggleMenu}>
                            <div className='margin-top-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><QrCode02Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="QR terminal" /></div>
                            </div>
                        </Link>
                        <a href='https://time2eat.today/' rel='nofollow'>
                            <div className='margin-top-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><ShoppingCart01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="Micromarkets" /></div>
                            </div>
                        </a>
                        <a href='https://subscription.uaf.org.ua/'>
                            <div className='margin-top-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><CreditCardRefreshSvg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="Recurring payments" /></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default React.memo(MenuLarge);