import React from 'react';
import '../../menu/MenuLarge.scss';
import {Link, useLocation} from 'react-router-dom';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import useQuery from '../../../useQuery';
import {isTrue} from '../../../utility';
import {queryParameters} from '../../../qs';
import {graphqlWhoamiNextQuery} from '../../graphql';
import {useAuthorizationState} from '../../../contexts/Authorization';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const AuthorizedUserMenu = () => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const location = useLocation();
    const qps = queryParameters(location);
    return (
        <>
            <li>
                <div
                    className='font-weight-bold overflow-wrap-break-word line-height-24px font-size-14px'
                >{whoamiNext.username || whoamiNext.email}</div>
            </li>
            <li>
                <a onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                        window.location.href = RAZZLE_APP_ACCOUNTS + '/home?cache=0&new=true'
                            + (qps.length ? '&' + qps.join('&') : '');
                    }, 500);
                }}>
                    <FormattedMessage defaultMessage='Account'/>
                </a>
            </li>
        </>
    )
}

function MenuLarge({menuLargeRef, menuFlag, closeMenu}) {
    const {more} = useQuery();
    const authorized = useAuthorizationState();
    const location = useLocation();
    return (
        <div className='menu-large' ref={menuLargeRef}>
            <div className={`menu-large__content ${menuFlag ? 'menu-large__content--active' : ''}`}>
                <ul className='menu-large__section-list'>
                    <li>
                        <Link to={`.${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Wallet'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Marketplace'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`impulses${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Orders'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/scan/tickets${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Scaner'/>
                        </Link>
                    </li>
                    <hr/>
                    <li>
                        <Link to={`/business${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Dashboard'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`dashboard${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Old dashboard'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`statistics${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Statistics'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/business/services${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Events'/>
                        </Link>
                    </li>
                    <li>
                        <Link to={`endpoints${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Tickets'/>
                        </Link>
                    </li>
                    {isTrue(more) &&
                    <li>
                        <Link to={`addls${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Additional fields'/>
                        </Link>
                    </li>
                    }
                    <li>
                        <Link to={`coupons${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Coupons'/>
                        </Link>
                    </li>
                    {isTrue(more) &&
                    <li>
                        <Link to={`pools${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Pools'/>
                        </Link>
                    </li>
                    }
                    <hr/>
                    {authorized &&
                        <AuthorizedUserMenu/>
                    }
                    <li>
                        <Link to={`/log-out${searchFilter(location.search)}`}>
                            <FormattedMessage defaultMessage='Log out'/>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default React.memo(MenuLarge);