import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useAuthorizationState} from '../../../contexts/Authorization';
import {graphqlWhoamiNextQuery} from '../../../components/graphql';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {useErrorMessagesDispatch} from '../../checkout/error-messages-context';
import {RouterLinkButton} from '../../../components/button/button';

const userNoPaymentNotification = {
    type: 'notification',
    title: <FormattedMessage defaultMessage='Event is still being set up'/>,
    description: <FormattedMessage defaultMessage='As soon as the organizer finishes setting up the event, you can buy a ticket'/>
};

const NoPaymentInfoUserMessage = React.memo(({merchant, serviceId}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const errorDispatch = useErrorMessagesDispatch();
    React.useEffect(() => {
        if (whoamiNext.id === merchant.id) {
            errorDispatch({
                type: 'add',
                payload: {
                    type: 'notification',
                    title: <FormattedMessage defaultMessage='Finish setting up the event'/>,
                    description: <FormattedMessage defaultMessage='To start sales, complete the event setup and add your payment information'/>,
                    showSupport: true,
                    action: <RouterLinkButton {...{color: 'primary', plain: true, size: 'sm', to: `/business/services/${serviceId}/publication`}}><FormattedMessage defaultMessage='Configure'/></RouterLinkButton>
                }
            });
        } else {
            errorDispatch({
                type: 'add',
                payload: userNoPaymentNotification
            });
        }
    }, [whoamiNext, merchant]);
    return (
        <></>
    )
});

const CheckPaymentInfo = ({serviceByIdOrSlug, setBuyAvailable}) => {
    const authorized = useAuthorizationState();
    const errorDispatch = useErrorMessagesDispatch();
    const {merchant, merchantAccount, id} = serviceByIdOrSlug;
    const paymentAccounts = [];
    if (merchantAccount) {
        paymentAccounts.push(merchantAccount);
    }
    React.useEffect(() => {
        if (!paymentAccounts.length) {
            setBuyAvailable(false);
            if (!authorized) {
                errorDispatch({
                    type: 'add',
                    payload: userNoPaymentNotification
                });
            }
        } else {
            setBuyAvailable(true);
        }
    }, [paymentAccounts, authorized]);
    return (
        <>
           {!paymentAccounts.length && authorized && <NoPaymentInfoUserMessage {...{merchant, serviceId: id}}/>}
        </>
    )
};

export default React.memo(CheckPaymentInfo);