import React from 'react';
import useQuery from '../useQuery';

const dim = (value) => value ? value.split(',') : [];

export default React.memo(({on, children}) => {
    const {apply} = useQuery();
    const applyDim = dim(apply);
    return (
        <>
            {applyDim.includes(on) && children}
        </>
    );
});