import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {Helmet} from 'react-helmet-async';
import Marked from '../../../components/Marked';
import i18n from '../../../i18n';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import {adopt} from '../../../math';
import {IN, OUT_NEGATIVE, DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../constants';
import ErrorFallback from '../../../components/ErrorFallback';
import EndpointsHeader, {WarpEndpointsHeader} from './endpoints-header';
import ServiceSchedule, {SERVICE_DESCRIPTION, ServiceScheduleCard} from './service-schedule';
import './basic.scss';
import getTagValue, {getServiceTagValue} from '../../../utilities/get-tag-value';
import SuspenseImageDynamicSize from '../../../components/SuspenseImageDynamicSize';
import CalendarIcon from '../../../assets/icons0/calendar.svg';
import {format} from 'date-fns';
import {getServiceEndDate, getServiceStartDate} from './get-service-date';
import FacebookIcon from '../../../assets/icons0/socials/facebook-icon.svg';
import InstagramIcon from '../../../assets/icons0/socials/instagram-icon.svg';
import TelegramIcon from '../../../assets/icons0/socials/telegram-icon.svg';
import moment from 'moment';
import {useParams} from 'react-router-dom';

const Endpoint = React.memo(({serviceByIdOrSlug}) => {
    const {locale = 'uk'} = useQuery();
    const ticketLink = getServiceTagValue(serviceByIdOrSlug.description, 'ticketLink');
    return (
        <div className='basic-endpoint border-radius-0dot75rem border-1px-solid padding-1dot25rem mw768-padding-2rem'>
            <div className='display-flex flex-direction-column-row'>
                <div className=' mw768-flex-1 mw768-margin-right-1rem'>
                    <div className='basic-endpoint__title text-lg medium mw768-text-xl'>
                        {i18n(serviceByIdOrSlug, 'name', locale)}
                    </div>
                    {serviceByIdOrSlug.target && moment(serviceByIdOrSlug.target).isValid() && <div className='basic-endpoint__date text-sm mw768-text-md padding-top-0dot5rem'>{moment(serviceByIdOrSlug.target).format('DD.MM.YYYY')}</div>}
                </div>
                <div className='display-flex mw768-flex-1 justify-content-space-between align-items-center mw768-align-self-center mw768-justify-content-flex-end padding-top-1rem mw768-padding-top-0'>
                    <div className='width-7rem min-height-2dot625rem'>
                        <noindex>
                            <a href={ticketLink} target='_blank' rel='nofollow' className='basic-endpoint__btn border-1px-solid padding-0dot5rem border-radius-0dot5rem text-md medium display-flex position-relative justify-content-center shadow-xs basic-endpoint__btn--empty cursor-pointer width-100percent' data-gtm='ad buy-btn'>
                                <span className='add-to-cart--visible'><FormattedMessage defaultMessage='Buy'/></span>
                            </a>
                        </noindex>
                    </div>
                </div>
            </div>
        </div>
    );
});

const AdService = ({ticketsBoxRef}) => {
    const {idOrSlug} = useParams();
    const {serviceByIdOrSlug} = useLazyLoadQuery(
        graphql`
            query adServiceQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    location
                    start
                    end
                    publish
                    target
                    tags
                }
            }
        `,
        {payload: decodeURIComponent(idOrSlug)}
    );
    const tags = serviceByIdOrSlug.description ? serviceByIdOrSlug.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) : [];
    const theme = getTagValue(tags, 'theme', {defaultValue: 'dark'});
    const posterUrl = getTagValue(tags, 'posterUrl');
    const targetEndVal = getTagValue(tags, 'targetEnd');
    const seoDescription = decodeURI(getTagValue(tags, 'seoDescription', {defaultValue: ''}));
    const seoTitle = decodeURI(getTagValue(tags, 'seoTitle', {defaultValue: ''}));
    const target = serviceByIdOrSlug.target ? format(new Date(serviceByIdOrSlug.target), 'dd-MM-yyyy') : null;
    const targetEnd = targetEndVal ? format(new Date(targetEndVal), 'dd-MM-yyyy') : null;
    const locationName = serviceByIdOrSlug.location;
    const facebook = getTagValue(tags, 'facebook');
    const instagram = getTagValue(tags, 'instagram');
    const telegram = getTagValue(tags, 'telegram');
    const {locale = 'uk'} = useQuery();
    const now = useNow();
    const serviceStart = getServiceStartDate(serviceByIdOrSlug.endpoints);
    const serviceEnd = getServiceEndDate(serviceByIdOrSlug.endpoints, serviceByIdOrSlug);
    const {state} = React.useMemo(() => adopt(now, serviceStart, serviceEnd, serviceByIdOrSlug.strategy,
        serviceByIdOrSlug.every), [now, serviceByIdOrSlug]);
    return (
        <>
        <Helmet>
            <title>{seoTitle || `${i18n(serviceByIdOrSlug, 'name', locale)}: Купуй квитки на ottry.com`}</title>
            <meta name='description' content={seoDescription || i18n(serviceByIdOrSlug, 'name', locale)}/>
            {!!posterUrl && <meta property='og:image' content={`https://${posterUrl}`}/>}
        </Helmet>
        <div>
            <div className={`view theme theme--${theme}`}>
                {posterUrl &&
                    <div className=''>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImageDynamicSize
                                className='basic__poster-img display-block max-height-35rem mw768-max-height-37dot5rem margin-0-auto max-width-100vw mw768-max-width-76rem'
                                src={`https://${posterUrl}`}
                            />
                        </ErrorBoundary>
                    </div>
                }
                <div className='block'>
                    <div className='max-width-50rem margin-0-auto'>
                        <h1 className='basic__title display-sm semibold padding-top-2rem mw768-padding-top-4rem'>
                            {i18n(serviceByIdOrSlug, 'name', locale)}
                        </h1>
                        {target &&
                            <div className='padding-top-0dot5rem basic__target display-flex'>
                                <CalendarIcon className='display-block height-1dot25rem width-1dot25rem'/>
                                <p className='text-md medium padding-left-0dot5rem'>{target}</p>
                                {targetEnd && target !== targetEnd &&
                                    <span className='text-md medium padding-left-0dot25rem'>{`— ${targetEnd}`}</span>
                                }
                            </div>
                        }
                        <div className='padding-top-1dot5rem'>
                            <Marked {...{content: i18n(serviceByIdOrSlug, 'details', locale)}}/>
                        </div>
                        <p className='marked text-md'>
                            <span className='bold'><FormattedMessage defaultMessage='Location'/>: </span>
                            <span>{ locationName ? decodeURI(locationName) : <FormattedMessage defaultMessage='To be announced'/>}</span>
                        </p>
                        {(facebook || instagram || telegram) &&
                            <div className='padding-top-1dot25rem mw768-padding-top-3rem display-flex gap-1dot25rem'>
                                {facebook &&
                                    <a href={facebook} target='_blank' rel='noopener noreferrer'>
                                        <FacebookIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {instagram &&
                                    <a href={instagram} target='_blank' rel='noopener noreferrer'>
                                        <InstagramIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {telegram &&
                                    <a href={telegram} target='_blank' rel='noopener noreferrer'>
                                        <TelegramIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                            </div>
                        }
                        <div className='margin-top-1rem mw768-margin-top-3rem padding-top-2rem padding-bottom-2rem' ref={ticketsBoxRef}>
                            <WarpEndpointsHeader {...{serviceByIdOrSlug: serviceByIdOrSlug, theme}}>
                                {({decrementEndpoint, incrementEndpoint, localCartState, checkout}) =>
                                    <EndpointsHeader {...{serviceByIdOrSlug: serviceByIdOrSlug, decrementEndpoint, incrementEndpoint, localCartState, checkout, showCart: false, theme}}/>
                                }
                            </WarpEndpointsHeader>
                            <div className='padding-top-2rem'>
                                <div>
                                    {i18n(serviceByIdOrSlug, 'details', locale) && i18n(serviceByIdOrSlug, 'details', locale).endsWith('!') ?
                                        <div className='padding-bottom-2rem'>
                                            <ServiceScheduleCard {...{...SERVICE_DESCRIPTION[OUT_NEGATIVE].default, theme}}/>
                                        </div>
                                        :
                                        <>
                                            <ServiceSchedule {...{
                                                start: serviceStart,
                                                end: serviceEnd,
                                                strategy: serviceByIdOrSlug.strategy,
                                                every: serviceByIdOrSlug.every,
                                                state,
                                                theme
                                            }}/>
                                            {state === IN &&
                                                <div>
                                                    <Endpoint {...{serviceByIdOrSlug: serviceByIdOrSlug}}/>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {state === IN && <p className='text-dm mw768-text-md color-gray-400 padding-top-0dot25rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default (AdService);