/**
 * @generated SignedSource<<a2728e991411a8691f35a63418370093>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "untie",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameUk",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Endpoint",
        "kind": "LinkedField",
        "name": "endpointById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "services",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PublishCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UntieCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PriceCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuantityCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DescriptionCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DetailsCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NameCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ServicesCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HintsCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SeasonCabinetEndpoint_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TagsCabinetEndpoint_endpoint"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EndpointByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Endpoint",
        "kind": "LinkedField",
        "name": "endpointById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "services",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionUk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detailsUk",
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "strategy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "every",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2d37b9f85d57ce643b73aeacab047ed",
    "id": null,
    "metadata": {},
    "name": "EndpointByIdQuery",
    "operationKind": "query",
    "text": "query EndpointByIdQuery(\n  $id: String\n) {\n  endpointById(id: $id) {\n    id\n    untie\n    services {\n      id\n      description\n      tags\n    }\n    ...PublishCabinetEndpoint_endpoint\n    ...UntieCabinetEndpoint_endpoint\n    ...PriceCabinetEndpoint_endpoint\n    ...QuantityCabinetEndpoint_endpoint\n    ...DescriptionCabinetEndpoint_endpoint\n    ...DetailsCabinetEndpoint_endpoint\n    ...NameCabinetEndpoint_endpoint\n    ...ServicesCabinetEndpoint_endpoint\n    ...HintsCabinetEndpoint_endpoint\n    ...SeasonCabinetEndpoint_endpoint\n    ...TagsCabinetEndpoint_endpoint\n  }\n}\n\nfragment DescriptionCabinetEndpoint_endpoint on Endpoint {\n  id\n  description\n  descriptionUk\n  version\n}\n\nfragment DetailsCabinetEndpoint_endpoint on Endpoint {\n  id\n  details\n  detailsUk\n  version\n}\n\nfragment HintsCabinetEndpoint_endpoint on Endpoint {\n  id\n  hints\n  version\n}\n\nfragment NameCabinetEndpoint_endpoint on Endpoint {\n  id\n  name\n  nameUk\n  version\n}\n\nfragment PriceCabinetEndpoint_endpoint on Endpoint {\n  id\n  price\n  version\n}\n\nfragment PublishCabinetEndpoint_endpoint on Endpoint {\n  id\n  publish\n  version\n}\n\nfragment QuantityCabinetEndpoint_endpoint on Endpoint {\n  id\n  quantity\n  version\n}\n\nfragment SeasonCabinetEndpoint_endpoint on Endpoint {\n  id\n  version\n  start\n  end\n  strategy\n  every\n}\n\nfragment ServicesCabinetEndpoint_endpoint on Endpoint {\n  id\n  services {\n    id\n    name\n    nameUk\n  }\n  version\n}\n\nfragment TagsCabinetEndpoint_endpoint on Endpoint {\n  id\n  tags\n  version\n}\n\nfragment UntieCabinetEndpoint_endpoint on Endpoint {\n  id\n  untie\n  version\n}\n"
  }
};
})();

node.hash = "2358564f8ae218b7951b0b34070b186b";

module.exports = node;
