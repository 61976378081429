import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {useParams, Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import Name from './properties/Name';
import Slug from './properties/Slug';
import Publish from './properties/Publish';
import Demo from './properties/Demo';
import Details from './properties/Details';
import Description from './properties/Description';
import SuccessImpulseEmailMarkdownTemplate from './properties/SuccessImpulseEmailMarkdownTemplate';
import SuccessImpulseEmailTemplate from './properties/SuccessImpulseEmailTemplate';
import Addls from './properties/Addls';
import Coupons from './properties/Coupons';
import Warp from './properties/Warp';
import Season from './properties/Season';
import Target from './properties/Target';
import MerchantAccountSalt from './properties/MerchantAccountSalt';
import MerchantIdPassword from './properties/MerchantIdPassword';
import classNames from 'classnames';
import {isSet} from '../../../utility';
import ShowMoreQueryParam from '../../ShowMoreQueryParam';
import Pools from './properties/Pools';
import Location from './properties/Location';
import Nopng from './properties/Nopng';
import ErrorFallback from '../../ErrorFallback';
import Pin from './properties/pin';
import './Service.scss';
import XCloseIcon from '../../../assets/icons0/x-close.svg';
import useQuery from '../../../useQuery';
import getTagValue, {getServiceTagValue} from '../../../utilities/get-tag-value';
import EffectRedirect from '../../EffectRedirect';

const technicalMerchantId = process.env.RAZZLE_TECHNICAL_MERCHANT;

const NewConstructorMessage = ({visibile, hideMessage}) => {
    const link = '/business/services/create';
    return (
        <div className={classNames('max-width-21dot25rem position-absolute top-4dot375rem right-1rem background-color-white border-2px-solid border-color-black padding-1rem', {'display-none': !visibile})}>
            <div className='display-flex padding-bottom-0dot5rem'>
                <p className='flex-1 text-lg bold color-black'><FormattedMessage defaultMessage='New convenient constructor is now available!'/></p>
                <div onClick={hideMessage} className='padding-left-0dot5rem cursor-pointer'>
                    <XCloseIcon className='width-1dot5rem height-1dot5rem display-block'/>
                </div>
            </div>
            <p className='color-black text-md padding-bottom-1dot5rem'>
                <FormattedMessage defaultMessage='Try to create a new event'/>
            </p>
            <Link to={link} className='display-block text-lg medium border-1px-solid border-color-black padding-0dot5625rem width-max-content'>
                <FormattedMessage defaultMessage='Try'/>
            </Link>
        </div>
    )
};

const Service = React.memo(() => {
    const {id} = useParams();
    const [showConstructorMessage, setShowConstructorMessage] = React.useState(false);
    const query = useLazyLoadQuery(
        graphql`
            query ServiceByIdQuery($id: String) {
                serviceById(id: $id) {
                    id
                    demo
                    partnerCode
                    warp
                    mrchntd
                    tags
                    description
                    ...SlugCabinetService_service
                    ...NameCabinetService_service
                    ...PublishCabinetService_service
                    ...DemoCabinetService_service
                    ...DetailsCabinetService_service
                    ...DescriptionCabinetService_service
                    ...SuccessImpulseEmailMarkdownTemplateCabinetService_service
                    ...SuccessImpulseEmailTemplateCabinetService_service
                    ...AddlsCabinetService_service
                    ...CouponsCabinetService_service
                    ...MerchantAccountSaltCabinetService_service
                    ...MerchantIdPasswordCabinetService_service
                    ...WarpCabinetService_service
                    ...SeasonCabinetService_service
                    ...TargetCabinetService_service
                    ...PoolsCabinetService_service
                    ...LocationCabinetService_service
                    ...NopngCabinetService_service
                    ...pinCabinetService_service
                }
            }
        `,
        {id}
    );
    const {apply = ''} = useQuery();
    const applyDim = apply ? apply.split(',') : [];
    const showBlack = applyDim.includes('black');
    const isNewConstructor = getTagValue(query.serviceById.tags, 'constructor') === 'new' || getServiceTagValue(query.serviceById.description, 'theme');
    if (!showBlack && isNewConstructor) {
        return <EffectRedirect to={`/business/services/${id}`} replace/>
    }
    return (
        <>
        <Name {...{service: query.serviceById}}/>
        <Demo {...{service: query.serviceById}}/>
        <ShowMoreQueryParam>
            <div className={classNames({'opacity-0dot2': query.serviceById.demo})}>
                <MerchantAccountSalt {...{service: query.serviceById}}/>
                {(isSet(query.serviceById.partnerCode)) &&
                <div className='font-style-italic margin-top-0dot5rem overflow-wrap-break-word'>
                    {query.serviceById.partnerCode}
                </div>
                }
                <MerchantIdPassword {...{service: query.serviceById}}/>
            </div>
            {query.serviceById.mrchntd === technicalMerchantId &&
                <React.Suspense fallback={<Skeleton/>}>
                    <Pools {...{service: query.serviceById}}/>
                </React.Suspense>
            }
        </ShowMoreQueryParam>
        <Details {...{service: query.serviceById}}/>
        <Location {...{service: query.serviceById}}/>
        <ShowMoreQueryParam>
            <Description {...{service: query.serviceById}}/>
        </ShowMoreQueryParam>
        <SuccessImpulseEmailMarkdownTemplate {...{service: query.serviceById}}/>
        <ShowMoreQueryParam>
            <div className='opacity-0dot2'>
                <SuccessImpulseEmailTemplate {...{service: query.serviceById}}/>
            </div>
        </ShowMoreQueryParam>
        <React.Suspense fallback={<Skeleton/>}>
            <Addls {...{service: query.serviceById}}/>
        </React.Suspense>
        <React.Suspense fallback={<Skeleton/>}>
            <Coupons {...{service: query.serviceById}}/>
        </React.Suspense>
        <Warp {...{service: query.serviceById}}/>
        <div className={classNames({'opacity-0dot2': !query.serviceById.warp})}>
            <Slug {...{service: query.serviceById}}/>
        </div>
        <Season {...{service: query.serviceById}}/>
        <Target {...{service: query.serviceById}}/>
        <ShowMoreQueryParam>
            <Nopng {...{service: query.serviceById}}/>
            <Publish {...{service: query.serviceById}}/>
            <Pin {...{service: query.serviceById}}/>
        </ShowMoreQueryParam>
        <NewConstructorMessage {...{visibile: showConstructorMessage, hideMessage: () => setShowConstructorMessage(false)}}/>
        </>
    );
});

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-1rem'>
                <Link className='focus' to={`/business/services${searchFilter(location.search)}`}>
                    &#60; <FormattedMessage defaultMessage='Events'/>
                </Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<Skeleton/>}>
                    <Service/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
