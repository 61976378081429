import React from 'react'
import {Link, useLocation, useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../components/Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import searchFilter from '../../../searchFilter';
import {FormattedMessage} from 'react-intl';
import {TICKETS_CONTACT_FORM, SUPPORT_CONTACT, CONTACT_EMAIL} from '../../../constants';
import IconMaster from '../../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../../assets/icons-payment/icon-google-pay.svg';
import ErrorFallback from '../../../components/ErrorFallback';
import Ogle from '../../../components/theme/Ogle';
import LogoWhiteDarkModeTrue from '../../../assets/logo-white-dark-mode-true.svg';
import LogoWhiteDarkModeFalse from '../../../assets/logo-white-dark-mode-false.svg';
import './basic.scss';
import getTagValue from '../../../utilities/get-tag-value';
import {ErrorMessagesProvider} from '../../checkout/error-messages-context';
import ErrorMessages from '../../checkout/error-messages';
import AdService from './ad-service';
import Service from './service';

const year = new Date().getFullYear();

const SwitchService = React.memo(({ticketsBoxRef}) => {
    const {idOrSlug} = useParams();
    const {serviceByIdOrSlug} = useLazyLoadQuery(
        graphql`
            query basicQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    tags
                }
            }
        `,
        {payload: decodeURIComponent(idOrSlug)}
    );
    const isAdPost = getTagValue(serviceByIdOrSlug.tags, 'constructor') === 'ad';
    if (isAdPost) {
        return (
            <AdService {...{ticketsBoxRef}}/>
        )
    }
    return (
        <Service {...{ticketsBoxRef}}/>
    )
});

const BasicWarp = () => {
    const location = useLocation();
    const ticketsBoxRef = React.useRef();
    const scrollToTickets = () => {
        ticketsBoxRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <Ogle>
            <ErrorMessagesProvider>
                <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                    <React.Suspense fallback={
                        <div className='view'>
                            <div className='block padding-top-2rem'>
                                <Skeleton/>
                            </div>
                        </div>
                    }>
                        <div className='pages'>
                            <div className='warp'>
                                <div className='basic'>
                                    <div className='view'>
                                        <div className='block basic__header'>
                                            <div className='padding-top-1rem padding-bottom-1rem mw768-padding-top-1dot25rem mw768-padding-bottom-1dot25rem display-flex justify-content-space-between align-items-center'>
                                                <Link to={`/events${searchFilter(location.search)}`}>
                                                    <LogoWhiteDarkModeTrue className='height-2rem logo logo--dark'/>
                                                    <LogoWhiteDarkModeFalse className='height-2rem logo logo--light'/>
                                                </Link>
                                                <div className='buy-tickets-btn border-radius-0dot5rem border-1px-solid border-color-gray-300 box-shadow-xs text-sm medium padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem cursor-pointer' onClick={() => scrollToTickets()}>
                                                    <FormattedMessage defaultMessage='Tickets'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SwitchService {...{ticketsBoxRef}}/>
                                    <div className='view'>
                                        <div className='block'>
                                            <div className='warp-footer margin-top-4rem border-top-1px-solid padding-top-2rem mw768-padding-top-3rem padding-bottom-2rem mw768-padding-bottom-3rem'>
                                                <Link to={`/events${searchFilter(location.search)}`}>
                                                    <LogoWhiteDarkModeTrue className='height-2rem logo logo--dark'/>
                                                    <LogoWhiteDarkModeFalse className='height-2rem logo logo--light'/>
                                                </Link>
                                                <div className='display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem'>
                                                    <div className='flex-1'>
                                                        <div className='margin-bottom-0dot5rem'>
                                                            <a className='text-md medium' href={`tel:${SUPPORT_CONTACT.cell}`} data-gtm='warp support-phone'>
                                                                {SUPPORT_CONTACT.cellFormatted}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a className='text-md medium color-primary-500' href={`mailto:${CONTACT_EMAIL}?subject=Warp support`} data-gtm='warp email'>{CONTACT_EMAIL}</a>
                                                        </div>
                                                    </div>
                                                    <div className='mw768-align-self-flex-end display-flex padding-top-2rem mw768-padding-top-0'>
                                                        <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                                        <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                                        <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                                        <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                                                    </div>
                                                </div>
                                                <div className='warp-footer display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem margin-top-2rem mw768-margin-top-4rem border-top-1px-solid'>
                                                    <div className='flex-order-2 mw768-flex-order-1 flex-1 padding-top-1rem mw768-padding-top-0'>
                                                        <p className='text-md'>© {year} Ottry. All rights reserved.</p>
                                                    </div>
                                                    <div className='flex-order-1 mw768-flex-order-2 display-flex flex-wrap-wrap gap-0dot5rem-1rem'>
                                                        <Link className='text-md' to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                                                        <Link className='text-md' to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                                                        <Link className='text-md' to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                                                        <a className='text-md' href={TICKETS_CONTACT_FORM} target='_blank'><FormattedMessage defaultMessage='I want to sell online'/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ErrorMessages/>
                    </React.Suspense>
                </ErrorBoundary>
            </ErrorMessagesProvider>
        </Ogle>
    );
};

export default React.memo(BasicWarp);